// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	insightsUrl: 'https://insights-dashboard.wellsitenavigator.xyz',
	name: 'LOCAL',
	apiHost: 'https://staging.api.v2.wellsitenavigator.com/v2/web',
	apiHostv3: 'https://staging.api.v2.wellsitenavigator.com',
	// apiHost: 'http://localhost:3000/v2/web',
	// apiHostv3: 'http://localhost:3000',
	googleMapsApiKey: 'AIzaSyB-WjJUSoNMV2yD5c5IqEOG0CqmP8lrEOw',
	accountPortalUrl: 'https://staging.account.wellsitenavigator.com',
	// accountPortalUrl: 'http://localhost:4200',
	cognitoConfig: {
		baseUrl: 'https://wn-staging.auth.us-west-1.amazoncognito.com',
		clientId: 'j2ffemret823gvfmfvuj7j875',
		redirectUri: 'https://staging.portal.wellsitenavigator.com/login',
	},
	firebaseConfig: {
		apiKey: 'AIzaSyC4mYcw7sp4buOgreXoVxf34_T8OzzZJl0',
		authDomain: 'wellsite-test-94725.firebaseapp.com',
		databaseURL: 'https://wellsite-test-94725.firebaseio.com',
		projectId: 'wellsite-test-94725',
		storageBucket: 'wellsite-test-94725.appspot.com',
		messagingSenderId: '201179007027',
		appId: '1:201179007027:web:22de0010c82c241ccd7b46',
		measurementId: 'G-P5JVD2G7VV',
		vapidKey: 'BJ4rpXyR33EO9XosD-mK0odlfFIMQ9uADWmgjkSQtUZe4SKepVDINvr5xKvlpfljJWco4UIrzYKVwvK2noDgpq0',
	},
	// googleMapsApiKey: 'AIzaSyB4plwhmRyGCtKDvBDWj1MXXP-TripjnJc',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
